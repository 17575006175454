.address-bar-footer {
    background: #191919;
    color: #fff;
    padding: 50px 0px 88px;
}

.addres-contet {
    position: relative;
    padding-left: 30px;
}

.addres-contet a {
    color: #fff;
    text-decoration: none;
}

.addres-contet .svg-address {
    position: absolute;
    left: 0px;
    top: -5px;
}

.addres-contet p.solid {
    font-weight: 600;
    font-size: 24px;
    line-height: 20px;
    font-family: 'Cormorant', serif;
}

.addres-contet p {
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    margin-top: 0px;
    margin-bottom: 10px;
    width: 300px;
}

.footer-tpcontet {
    padding-top: 51px;
    padding-bottom: 45px;
}

.footer-txt p {
    margin-left: 0px !important;
    color: #FFF !important;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 300 !important;
    line-height: 186% !important;
    width: 77%;
}

.footer-nav {
    padding-top: 20px;
}

.footer-useful-link h5 {
    margin-top: 0px;
    margin-bottom: 5px;
    color: #AA8453;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
}

.call-position p {
    position: absolute;
    top: -26px;
    left: 28px;
    color: #FFF !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 300 !important;
    line-height: 32px !important;
    margin-bottom: 0px !important;
    white-space: nowrap;
}

.span-location-footer {
    position: absolute;
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px;
    top: 1px;
    left: 30px;
    white-space: nowrap;
    // white-space: break-spaces;
}

.span-name {
    top: -16px;
    left: 30px;
    position: absolute;
    color: #AA8453;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 5px;
    text-transform: uppercase;
}

.footer-nav ul {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.footer-nav ul li a {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    padding: 0.5rem 2rem;
    text-decoration: none;
}

.contact-us-txt {
    margin-top: 19px;
}

.cpyright {
    border-top: 1px solid #363636;
    padding: 18px 0px;
    margin-top: 30px;
}

.inner-txt {
    color: #AA8453;
}

.copyright-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #4D4D4D;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
}


.footer-social-outer {
    display: flex;
    justify-content: end;
}

.footer-bg-sect {
    padding-top: 40px;
}

.footer-social>a:hover .dot-before {
    opacity: 1;
}

.footer-social>a .dot-before {
    margin: 0px 17px;
}


.dot-before {
    background-color: #AA8453 !important;
    height: 5px;
    width: 5px;
    border-radius: 100%;
    margin-right: 8px;
    opacity: 0;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    display: inline-block;
}

.footer-social a {
    color: #AA8453;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.68px;
    text-transform: uppercase;
    text-decoration: none;
}



.footer-upper-section {
    padding: 50px 0px;
    background: #F4E2C7;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-position: 0px 0px;
    background-repeat: no-repeat;
    background-size: cover;
}
.footer-upper-section::before {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    content: "";
    background: #353530;
    opacity: .93;
}

.footer-upper-section p {
    color: #fff;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px;
    letter-spacing: 2.2px;
    margin: 0px;
}


.footer-section-txt {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.footer-inner-txt {
    padding: 0px;
    width: 70%;
}
.footer-inner-btn {
    margin-left: auto;
}

.footer-btn {
    width: 140px;
    height: 36px;
    color: #fff;
    border-radius: 12px;
    border: 1px solid #FFF;
    text-align: center;
    font-family: Nunito Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    background-color: transparent;
    cursor: pointer;
}
.footer-links a {
    color: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 38px;
    text-decoration: none !important;
}
@media(max-width: 1299px) {
    .cpyright {
        text-align: center;
    }

    .footer-social-outer {
        display: flex;
        justify-content: center;
    }

    .footer-social a {
        margin-left: 0px;
    }
    .footer-section-txt {
        flex-flow: column;
        text-align: center;
    }
    .footer-inner-txt {
        padding: 0px;
        width: 100%;
    }
    .footer-inner-btn {
        margin-left: 0px;
        margin-top: 20px;
    }
}

@media(max-width: 959px) {
    .footer-nav ul li a {
        padding: 0.5rem 1rem;
    }
}

@media(max-width: 767px) {
    .footer-tpcontet {
        padding-top: 0px;
        padding-bottom: 25px;
    }

    .footer-bg-sect {
        padding-top: 40px;
        height: 320px;
    }

    .footer-useful-link {
        margin-top: 60px;
    }
    .payment-header {
        margin-top: 40px;
        margin-bottom: -40px;
    }

    .footer-upper-section p {
        font-size: 16px;
        line-height: 31px;
    }

    .footer-inner-txt {
        padding-top: 0px;
    }

    .footer-useful-link h5 {
        margin-top: 10px;
        margin-bottom: 5px;
        font-size: 24px;
    }

    .footer-txt p {
        width: 100%;
    }

    .footer-links a {
        color: #000;
    }

    .call-position p {
        position: relative;
        top: 0px;
        left: auto;
        color: #000 !important;
        font-size: 18px !important;
        margin-top: 3px;
        margin-bottom: 20px !important;
    }

    .footer-location {
        position: relative;
        margin-top: 1px;
    }

    .span-name {
        top: 0px;
        left: auto;
        position: relative;
    }

    .span-location-footer {
        position: relative;
        color: #000;
        font-size: 18px;
        top: 0px;
        left: auto;
        display: block;
        margin-top: -10px;
        margin-bottom: 13px;
        white-space: break-spaces;
    }

    
    .footer-cent-mb {
        text-align: center;
    }

    .social-icons {
        justify-content: center;
    }

    .foter-contact-iocn svg path {
        fill: #000;
    }

    .cpyright {
        padding: 18px 0px 12px;
        border-top: 1px solid #363636;
        background: #1F2127;
        text-align: center;
    }
    .copyright-text {
        font-size: 12px;
        margin-bottom: -9px;
        display: block;
    }
}